<template>
  <div v-if="menuItems">
    <u-dropdown :items="menuItems" :popper="{ placement: 'top-end' }">
      <u-button :icon="COMMON_ICONS.moreVertical" size="xs" variant="ghost" color="charcoal" />
    </u-dropdown>
  </div>
</template>

<script setup>
  const props = defineProps({
    album: Object
  });

  const {getContextActions} = useAlbumActions();

  const actions = getContextActions({
    album: props.album,
    userId: props.album.user_id
  });

  const modes = {
    owner: [
      [
        actions.share,
        actions.edit,
        actions.editThumbnail,
        actions.move,
        actions.download
      ],
      [
        actions.delete
      ]
    ],
    visitedShare: [
      [
        actions.copy,
        actions.download
      ],
      [
        actions.removeVisited
      ]
    ],
    general: [
      [
        actions.copy,
        actions.download
      ]
    ]
  };

  const mode = computed(() => {
    const {isAuthenticated, currentUserId} = useSessionStore();
    if (!isAuthenticated) {
      return false;
    }

    if (props.album.user_id === currentUserId) {
      return 'owner';
    } else if (props.album.sharedItemId) {
      return 'visitedShare';
    } else {
      return 'general';
    }
  });

  const menuItems = computed(() => modes[mode.value]);

</script>
